import {
	Feedback,
	FeedbackActions,
	FeedbackBody,
	FeedbackHeader,
	FeedbackIcon,
	FeedbackText,
	FeedbackTitle,
} from '@playbooks/interface/feedbacks';

const SlideFeedback = ({ icon, title, text, children, tailwind }) => {
	// Render
	return (
		<Feedback align='text-center' spacing='p-4' {...tailwind?.feedback}>
			<FeedbackHeader>
				<FeedbackIcon icon={icon} fontSize='text-3xl' {...tailwind?.icon} />
			</FeedbackHeader>
			<FeedbackBody>
				<FeedbackTitle>{title}</FeedbackTitle>
				<FeedbackText>{text}</FeedbackText>
			</FeedbackBody>
			{children && <FeedbackActions>{children}</FeedbackActions>}
		</Feedback>
	);
};

export { SlideFeedback };
