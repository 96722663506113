import { Fragment } from 'react';

import { Div } from '@playbooks/interface/html';
import { Feedback } from 'molecules/feedbacks';
import { Skeleton } from 'molecules/skeletons';
import { listBuilder } from 'utils';

const AvatarList = ({
	type = 'avatar',
	icon = 'database',
	title,
	text,
	data = [],
	loading,
	count,
	renderItem,
	tailwind,
}) => {
	const props = { loading, tailwind };
	const RenderItem = props => renderItem(props);
	// Render
	return (
		<Fragment>
			{loading ? (
				<Fragment>
					{listBuilder(count).map((v, i) => (
						<Skeleton type={type} tailwind={tailwind} />
					))}
				</Fragment>
			) : (
				<Fragment>
					{data.length > 0 ? (
						<Div display='flex-wrap' space='space-x-2 space-x-reverse'>
							{data?.map((item, i) => (
								<RenderItem key={i} item={item} className={i === 0 ? 'mr-2 mb-2' : 'mb-2'} {...props} />
							))}
						</Div>
					) : (
						<Feedback type='preview' icon={icon} title={title} text={text} tailwind={tailwind} />
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export { AvatarList };
