import { Fragment } from 'react';

import { Col, Grid } from '@playbooks/interface/grid';
import { Div } from '@playbooks/interface/html';
import { Feedback } from 'molecules/feedbacks';
import { Skeleton } from 'molecules/skeletons';
import { listBuilder } from 'utils';

const DropList = ({
	type = 'drop',
	icon = 'database',
	title = '',
	text = '',
	data = [],
	count = 3,
	loading = false,
	children,
	tailwind,
}) => {
	// Render
	return (
		<Fragment>
			{data.length > 0 || loading ? (
				<Grid gap='' {...tailwind?.grid}>
					{loading ? (
						<Fragment>
							{listBuilder(count).map((v, i) => (
								<Col key={i}>
									<Skeleton type={type} tailwind={tailwind} />
								</Col>
							))}
						</Fragment>
					) : (
						<Fragment>{children}</Fragment>
					)}
				</Grid>
			) : (
				<Div spacing='p-4'>
					<Feedback type={type} icon={icon} title={title} text={text} tailwind={tailwind} />
				</Div>
			)}
		</Fragment>
	);
};

export { DropList };
