import { Fragment } from 'react';

import { Col, Grid } from '@playbooks/interface/grid';
import { Feedback } from 'molecules/feedbacks';
import { Skeleton } from 'molecules/skeletons';
import { listBuilder } from 'utils';

const LinkList = ({
	type = 'link',
	icon = 'database',
	title,
	text,
	data = [],
	loading,
	count,
	rootLink,
	renderItem,
	tailwind,
}) => {
	const props = { loading, rootLink, tailwind };
	const RenderItem = props => renderItem(props);

	// Render
	return (
		<Fragment>
			{loading ? (
				<Grid {...tailwind?.grid}>
					{listBuilder(count).map((v, i) => (
						<Col key={i} sm='6' md='6' lg='4'>
							<Skeleton type={type} tailwind={tailwind} />
						</Col>
					))}
				</Grid>
			) : (
				<Fragment>
					{data.length > 0 ? (
						<Grid {...tailwind?.grid}>
							{data?.map((item, i) => (
								<Col key={i} sm='6' md='6' lg='4'>
									<RenderItem item={item} {...props} />
								</Col>
							))}
						</Grid>
					) : (
						<Feedback type={type} icon={icon} title={title} text={text} tailwind={tailwind} />
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export { LinkList };
